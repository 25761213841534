export class CreateApplicationImplementationTypeAttachment {
  static readonly type = '[ApplicationImplementationType] Create attachment';

  constructor(
    public applicationImplementationTypeId: string,
    public file: File,
    public description: string
  ) {}
}

export class RemoveApplicationImplementationTypeAttachment {
  static readonly type = '[ApplicationImplementationType] Remove attachment';

  constructor(
    public applicationImplementationTypeId: string,
    public attachmentId: number
  ) {}
}

export class UpdateApplicationImplementationTypeAttachment {
  static readonly type = '[ApplicationImplementationType] Update attachment';

  constructor(
    public applicationImplementationTypeId: string,
    public attachmentId: number,
    public description: string
  ) {}
}

export class DownloadApplicationImplementationTypeAttachment {
  static readonly type = '[ApplicationImplementationType] Download attachment';

  constructor(
    public applicationImplementationTypeId: string,
    public attachmentId: number
  ) {}
}

export class ListApplicationImplementationTypeAttachments {
  static readonly type = '[ApplicationImplementationType] List attachments';

  constructor(public applicationImplementationTypeId: string) {}
}
